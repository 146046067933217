import { EBandType } from '@trader/types';

export const pingStrategyInterval = 15000; // 15 sec

export const fullyFinishedBackTest = 100;

export const minMultiplier = 0.01;
export const maxMultiplier = 10;
export const defMultiplier = 1;

export const lowMultiplier = 0.1;
export const highMultiplier = 2.55;
export const multiplierStep = 0.1;

export const defSl = '100';
export const maxSl = 1000000;

export const minQuantity = 1;

export interface IIBandItem {
  id: string;
  type: EBandType;
  value: string;
  title?: string;
  order: number;
}

interface IBands {
  [key: string]: IIBandItem;
}

export const BANDS: IBands = {
  RoundedVwap: {
    id: 'RoundedVwap',
    title: 'VWAP',
    type: EBandType.upper,
    value: '0',
    order: 9,
  },
  Strip025High: {
    id: 'Strip025High',
    type: EBandType.upper,
    value: '1',
    order: 8,
  },
  Strip0HalfHigh: {
    id: 'Strip0HalfHigh',
    type: EBandType.upper,
    value: '2',
    order: 7,
  },
  Strip075High: {
    id: 'Strip075High',
    type: EBandType.upper,
    value: '3',
    order: 6,
  },
  Strip1High: { id: 'Strip1High', type: EBandType.upper, value: '4', order: 5 },
  Strip1HalfHigh: {
    id: 'Strip1HalfHigh',
    type: EBandType.upper,
    value: '5',
    order: 4,
  },
  Strip2High: { id: 'Strip2High', type: EBandType.upper, value: '6', order: 3 },
  Strip2HalfHigh: {
    id: 'Strip2HalfHigh',
    type: EBandType.upper,
    value: '7',
    order: 2,
  },
  Strip3High: { id: 'Strip3High', type: EBandType.upper, value: '8', order: 1 },
  Strip025Low: {
    id: 'Strip025Low',
    type: EBandType.lower,
    value: '1',
    order: 8,
  },
  Strip0HalfLow: {
    id: 'Strip0HalfLow',
    type: EBandType.lower,
    value: '2',
    order: 7,
  },
  Strip075Low: {
    id: 'Strip075Low',
    type: EBandType.lower,
    value: '3',
    order: 6,
  },
  Strip1Low: { id: 'Strip1Low', type: EBandType.lower, value: '4', order: 5 },
  Strip1HalfLow: {
    id: 'Strip1HalfLow',
    type: EBandType.lower,
    value: '5',
    order: 4,
  },
  Strip2Low: { id: 'Strip2Low', type: EBandType.lower, value: '6', order: 3 },
  Strip2HalfLow: {
    id: 'Strip2HalfLow',
    type: EBandType.lower,
    value: '7',
    order: 2,
  },
  Strip3Low: { id: 'Strip3Low', type: EBandType.lower, value: '8', order: 1 },
};
